<template>
  <div
    :class="`theme-main-menu sticky-menu theme-menu-seven bg-none fixed ${
      isSticky ? 'fixed' : ''
    }`"
  >
    <div class="d-flex align-items-center justify-content-between">
      <div class="logo order-lg-1">
        <router-link to="/">
          <img src="@/assets/images/logo/logo_1.svg" alt=""
        /></router-link>
      </div>

     <div class="right-widget order-lg-3">
        <ul class="d-flex align-items-center">
          <li>
            <a class="theme-btn-two d-none d-lg-block" href="https://www.buymeacoffee.com/findmyai" target="_blank">Buy Me A Coffee </a>
          </li>
        </ul>
      </div> 
      <nav
        id="mega-menu-holder"
        class="navbar navbar-expand-lg ms-xl-5 order-lg-2"
      >
        <div class="nav-container">
          <button
            class="navbar-toggler"
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavbar"
            :class="menuActive ? 'navbar-toggler open' : 'navbar-toggler'"
            @click="menuActive = !menuActive"
          >
            <span></span>
          </button>
          <div
            :class="
              menuActive
                ? 'navbar-collapse collapse ss'
                : 'navbar-collapse collapse'
            "
            id="collapsibleNavbar"
          >
            <div class="d-lg-flex justify-content-between align-items-center">
              <ul
                class="navbar-nav main-side-nav font-gordita"
                id="one-page-nav"
              >
                <li class="nav-item">
                  <a href="/" class="nav-link"
                    ><span @click.self="menuActive = !menuActive"
                      >Home</span
                    ></a
                  >
                </li>
                <li class="nav-item">
                  <a href="/news" class="nav-link"
                    ><span @click.self="menuActive = !menuActive"
                      >Discover</span
                    ></a
                  >
                </li>
                <li class="nav-item">
                  <a href="/submit" class="nav-link"
                    ><span @click.self="menuActive = !menuActive"
                      >Search</span
                    ></a
                  >
                </li>
                <li class="nav-item">
                  <a href="/contact" class="nav-link"
                    ><span @click.self="menuActive = !menuActive"
                      >Contact</span
                    ></a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>

export default {
  name: "HeaderEleven",
  components: {
  },
  data() {
    return {
      isSticky: false,
      menuActive: false,
    };
  },
  methods: {
    handleSticky() {
      if (window.scrollY > 80) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleSticky);
  },
};
</script>
