export default {
  data() {
    return {
      Items: [
        {
          id: 3718,
          title: 'Heubot',
          short_description: 'Detect Cognitive Biases in text.',
          description:
            "Heubot is an AI tool designed to detect cognitive biases in text, helping to identify potential biases in written content and promote greater awareness of how our biases can affect our communication. The tool uses advanced natural language processing and machine learning algorithms to analyze written content and identify instances where the author may be demonstrating unconscious bias. Heubot can detect a wide range of biases, including stereotyping, overgeneralizing, and making assumptions without evidence.One of the key benefits of Heubot is its ability to promote greater awareness of cognitive biases in written communication. By analyzing written content and identifying instances where biases may be present, Heubot can help writers become more aware of their own biases and make more conscious, informed decisions about how they write. This can ultimately lead to more accurate, unbiased communication and a greater understanding of the ways in which our own biases can shape the way we interpret the world around us. Heubot is also highly customizable, allowing users to tailor the tool to their specific needs and preferences. Users can adjust the tool's settings to focus on specific types of biases, or to analyze content from different perspectives or contexts. This makes Heubot a highly flexible tool that can be used in a wide range of settings, from academic research and professional writing to social media and everyday communication.",
          internal_link: 'https://findmyai.co/tool/heubot/',
          category_1: 'Writing',
          merged_category: 'Writing bias text',
          date_added: '29 Apr 2023',
          image: 'https://i.imgur.com/Ibpe0k1.png',
          img: 'https://findmyai.co/media/heubot.png',
          price: 'Free',
          price_detail: 'Free',
          external_link: 'https://heubot.com/?ref=findmyai',
          upvote: null,
          editors_pick: null,
        },
        {
          id: 1565,
          title: 'AI Host',
          short_description:
            'Interactive robotic virtual host for live streaming.',
          description:
            'LiveReacting is an AI technology platform designed to make live streaming more interactive and engaging. It provides a virtual AI host to automate your live shows, eliminating the need for a human host.  With LiveReacting, you can add trivia games, word searches, wordict games, and last comment wins games to your show, as well as offer countdowns and pre-recorded videos.  The AI host understands the context of your show and will interact with players in real-time with educational and entertaining content. It can also be customized with a custom avatar and will support multiple languages.  It is free to use while in the beta version, and it can stream to up to 20 destinations. Additionally, you can add cameras and other elements such as polls and games.  LiveReacting can help you revolutionize your live streams and captivate your audience.',
          internal_link: 'https://findmyai.co/tool/ai-host/',
          category_1: 'Live streams',
          merged_category: 'Live streams host stream        ',
          date_added: '27 Dec 2022',
          image: 'https://media.theresanaiforthat.com/ai-host.png',
          img: 'https://findmyai.co/media/ai-host.png',
          price: 'Paid',
          price_detail: 'From $19.99/mo',
          external_link:
            'https://www.livereacting.com/ai-host-for-live-stream?ref=findmyai',
          upvote: null,
          editors_pick: null,
        },
        {
          id: 2454,
          title: 'AI Resume Checker',
          short_description: 'Resume analysis with personalized tips.',
          description:
            'The AI Powered Resume Checker is a powerful tool for job seekers looking to improve their resumes. Powered by ChatGPT, the resume checker service provides a detailed analysis of your resume including personalized tips to improve it.  It supports English language only and works with PDF files. Submissions are limited to one resume per person to ensure fairness. After submission, an email with the review is sent to the job seeker.  Additionally, the Resoume website provides ready made templates and an AI Assistant to help tailor resumes to the desired job. The Resoume website also offers a logo and other helpful resources to help job seekers get hired faster.',
          internal_link: 'https://findmyai.co/tool/ai-resume-checker/',
          category_1: 'Resume analysis',
          merged_category: 'Resume analysis resume analysis        ',
          date_added: '24 Feb 2023',
          image: 'https://media.theresanaiforthat.com/ai-resume-checker.png',
          img: 'https://findmyai.co/media/ai-resume-checker.png',
          price: 'Free',
          price_detail: 'Free',
          external_link: 'https://www.resumechecker.ai/?ref=findmyai',
          upvote: null,
          editors_pick: null,
        },
        {
          id: 2929,
          title: 'AI Transcriptions by Riverside',
          short_description: 'Video and audio file transcription.',
          description:
            "Riverside is an AI transcription tool that allows users to transcribe audio and video files in over 100 languages quickly and accurately. The tool is designed to be user-friendly, with a simple interface that enables users to upload audio or video files in MP3, WAV, MP4 or MOV format with just a few clicks.  The transcription times may vary depending on the file size, length of the content, and server activity. The tool is powered by the latest AI technology, which enables it to offer super accurate transcriptions in multiple languages.  The transcripts are available in TXT and SRT formats and can be downloaded for free with no sign-up required. Riverside's transcription tool offers speaker detection that identifies who is speaking, making it easy for users to follow the conversation.  Additionally, Riverside offers high-quality audio and video recording and simple editing capabilities, making it an all-in-one solution for recording and transcribing content.  The tool is ideal for podcasters, journalists, and researchers who need to transcribe recorded content. Riverside offers an introductory special, and the privacy policy is available on the website.",
          internal_link:
            'https://findmyai.co/tool/ai-transcriptions-by-riverside/',
          category_1: 'Video transcription',
          merged_category:
            'Video transcription video transformation edit audio transcription  ',
          date_added: '18 Mar 2023',
          image:
            'https://media.theresanaiforthat.com/ai-transcriptions-by-riverside.png',
          img: 'https://findmyai.co/media/ai-transcriptions-by-riverside.png',
          price: 'Free',
          price_detail: 'Free',
          external_link: 'https://riverside.fm/transcription?ref=findmyai',
          upvote: null,
          editors_pick: null,
        },
        {
          id: 3160,
          title: 'AIbot',
          short_description: 'Chatbot platform for messaging apps.',
          description:
            "The AI Bot is a low-code platform that allows users to build AI chatbots easily and quickly, which can be integrated with various popular messaging apps such as WhatsApp, Twitter, SMS, MMS, and Telegram.  With the power of low-code, users can start building bots without coding experience, and then make adjustments to the bot's functionality based on their desired use case.  The tool also provides a range of features such as image and voice bots, as well as the ability to manage data with Table UI and Cloud Functions visually.  Additionally, the tool provides templates with instant ready-to-use integrations that allow users to build bots with minimal effort. The Rowy platform offers a collaborative environment where users can invite their team members and manage workflows with granular access controls.  The platform is secure, and data, API keys, and cloud functions are all kept safe on the user's own Google Cloud project. Overall, AI Bot is a comprehensive tool that allows its users to build powerful AI bots on various messaging platforms, making it an excellent choice for businesses or individuals looking to enhance their customer engagement through automation.",
          internal_link: 'https://findmyai.co/tool/aibot/',
          category_1: 'Chatbots',
          merged_category: 'Chatbots chatbot generator chat conversation gpt  ',
          date_added: '29 Mar 2023',
          image: 'https://media.theresanaiforthat.com/aibot.png',
          img: 'https://findmyai.co/media/aibot.png',
          price: 'Free',
          price_detail: 'Free',
          external_link: 'https://www.aibot.how/?ref=findmyai',
          upvote: null,
          editors_pick: null,
        },
      ],
    }
  },
}
