<template>
  <div class="main-page-wrapper p0 overflow-hidden">
    <Header/>
    <ContactForm/>
  </div>
</template>

<script>
import Header from './Header.vue';
import ContactForm from './ContactForm.vue'

export default {
  name:'ContactDoc',
  components:{
    Header,
    ContactForm
    }
}
</script>