<template>
  <div class="main-page-wrapper font-gordita overflow-hidden">
    <Header/>
    <Journey/>
  </div>
</template>

<script>
import Header from './Header.vue';
import Journey from './Journey.vue';

export default {
    name: "WebsiteBuilderMain",
    components: { Header, 
    Journey,
    }
}
</script>
<style scoped>
.main-page-wrapper {
    overflow-x: hidden;
    padding-top: 170px;
}
</style>