<template>
<div class="fancy-hero-four bg-doc space-fix">
    <div class="bg-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-xl-9 col-lg-11 col-md-10 m-auto">
            <h6>Contact us</h6>
            <h2 style="color:#0a2a30">Feel free to contact us or just say hi!</h2>
          </div>
        </div>
      </div>
    </div> <!-- /.bg-wrapper -->
  </div>
  <div class="contact-style-two">
    <div class="container">
      <div class="contact-info-wrapper">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-sm-6 d-lg-flex">
            <div class="address-info">
              <div class="icon d-flex align-items-end"><img src="../../assets/images/icon/44.svg" alt=""></div>
              <div class="title">Location</div>
              <p class="gilroy-bold">Minneapolis, MN</p>
            </div> <!-- /.address-info -->
          </div>
          <div class="col-lg-4 col-sm-6 d-lg-flex">
            <div class="address-info">
              <div class="icon d-flex align-items-end"><img src="../../assets/images/icon/45.svg" alt=""></div>
              <div class="title">Contact</div>
              <p class="gilroy-bold">findmyaitools@gmail.com</p>
            </div> <!-- /.address-info -->
          </div>
          <div class="col-lg-4 col-sm-6 d-lg-flex">
            <div class="address-info">
              <div class="icon d-flex align-items-end"><img src="../../assets/images/icon/46.svg" alt=""></div>
              <div class="title">Social Media</div>
              <p class="gilroy-bold">Find on social media</p>
              <ul class="d-flex justify-content-center">
                <li><a href="https://www.facebook.com/findmyai"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
									<li><a href="https://www.twitter.com/findmyai"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
									<li><a href="https://www.linkedin.com/company/findmyai"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                
              </ul>
            </div> <!-- /.address-info -->
          </div>
        </div>
        <img src="../../assets/images/shape/64.svg" alt="" class="shapes shape-one">
      </div> <!-- /.contact-info-wrapper -->

    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactStyle',
  props:{contact_doc:Boolean},
  methods: {
    onSubmit() {
      console.log('Submitted');
    },
  },
}
</script>
<style scoped>

.fancy-hero-four.bg-doc {
    background: #f9f8f4;
}
</style>