export default {
  data() {
      return {
        portfolioItems: [
          //  v1
          {
            id: 1,
            img: 'https://uploads-ssl.webflow.com/63994dae1033718bee6949ce/63f2f8a25f6fc838ea84626e_2short-ai-logo.png',
            title: 'The Gang Blue',
            tag: 'Development, Design',
            subtitle: 'Very good subtitle',
            category: 'dev',
          },
          {
            id: 2,
            img: 'https://uploads-ssl.webflow.com/63994dae1033718bee6949ce/63f1ce2b7f1a4112524bcb2b_SEO_3dfy_fb-1.jpeg',
            title: 'Core WordPress',
            tag: 'Development, Plugin',
            category: 'plugin',
          },
          {
            id: 3,
            img: 'https://uploads-ssl.webflow.com/63994dae1033718bee6949ce/63b71aada698140e64acb6c7_63a9f1bdf1c7f62815f25bd9_1661503084835_c464da95.webp',
            title: 'Platform Audit',
            tag: 'Development, Branding',
            category: 'design',
          },
          {
            id: 4,
            img: 'https://uploads-ssl.webflow.com/63994dae1033718bee6949ce/64048ac481bc5fe81405db5d_88stacks-logo.png',
            title: 'Medieval origins',
            tag: 'Development, Design',
            subtitle: 'Development',
            category: 'branding',
          },
          {
            id: 5,
            img: 'https://uploads-ssl.webflow.com/63994dae1033718bee6949ce/63be4b8a63863b10a10170d0_63bb672b4335da8dffb87c37_Idea.jpeg',
            title: 'Designer’s Checklist',
            tag: 'Development, Plugin',
            subtitle: 'Development',
            category: 'dev',
          },
          {
            id: 6,
            img: 'https://uploads-ssl.webflow.com/63994dae1033718bee6949ce/63dab0e4d05b02c4e41c556d_meta-image.png',
            subtitle: 'Motivation defining Moment',
            tag: 'Development, Design',
            title: 'ChatGPT',
            category: 'plugin',
          },
          {
            id: 7,
            img: 'https://uploads-ssl.webflow.com/63994dae1033718bee6949ce/63d02f7d9e8158c3d81c90f1_adaptiv.png',
            subtitle: 'Our experience WordCamp',
            tag: 'Development, Branding',
            title: 'Stacy Adams',
            category: 'design',
          },
          {
            id: 8,
            img: 'https://uploads-ssl.webflow.com/63994dae1033718bee6949ce/639ccece8defc03268ba6961_630601ea4dc69407d51c916f_MetaImg.jpeg',
            subtitle: 'Lets catch up amd',
            tag: 'Development, Design',
            title: 'FildesAI',
            category: 'plugin',
          },
          //  v-2
          {
            id: 9,
            img: 'https://uploads-ssl.webflow.com/63994dae1033718bee6949ce/63dd61c366106b20af97c63a_og-image.png',
            title: 'The Gang Blue',
            tag: 'Development, Design',
            subtitle: 'Development',
            category: 'dev-2',
          },
          {
            id: 10,
            img: 'https://uploads-ssl.webflow.com/63994dae1033718bee6949ce/641a70c86adf1eea725cf958_92e0dbbdc00eab4ce77c.jpeg',
            title: 'Core WordPress',
            tag: 'Development, Plugin',
            video: true,
            category: 'plugin-2',
          },
          {
            id: 11,
            img: 'https://uploads-ssl.webflow.com/63994dae1033718bee6949ce/639ccece51585124b6b4ac8b_timemachine_6.jpeg',
            title: 'Platform Audit',
            tag: 'Development, Branding',
            category: 'design-2',
          },
          {
            id: 12,
            img: 'https://uploads-ssl.webflow.com/63994dae1033718bee6949ce/64089db81b69177fa81daafa_adrenaline-logo.png',
            title: 'Medieval origins',
            tag: 'Development, Design',
            video: true,
            category: 'branding-2',
          },
          {
            id: 13,
            img: 'https://uploads-ssl.webflow.com/63994dae1033718bee6949ce/63b9e28d2551256faf4d4544_61b9f4a385fa68f24cf0326e_Untitled-1-01.png',
            title: 'Designer’s Checklist',
            tag: 'Development, Plugin',
            category: 'dev-2',
          },
          {
            id: 14,
            img: 'https://uploads-ssl.webflow.com/63994dae1033718bee6949ce/63caf582b5d3a8c29ca6e415_5fc61b35ce2996b2983474da_opengraph-home.png',
            title: 'Motivation defining Moment',
            tag: 'Development, Design',
            category: 'design-2',
          },
          // v-3

          {
            id: 15,
            img: 'https://uploads-ssl.webflow.com/63994dae1033718bee6949ce/63f6f3651ffc8c0c73bf2511_ar-logo-fbthumbnail.webp',
            title: 'The Gang Blue',
            tag: 'Development, Design',
            category: 'dev-3',
          },
          {
            id: 16,
            img: require(`@/assets/images/gallery/img_16.jpg`),
            title: 'Core WordPress',
            tag: 'Development, Plugin',
            video: true,
            category: 'plugin-3',
          },
          {
            id: 17,
            img: require(`@/assets/images/gallery/img_17.jpg`),
            title: 'Platform Audit',
            tag: 'Development, Branding',
            category: 'design-3',
          },
          {
            id: 18,
            img: require(`@/assets/images/gallery/img_18.jpg`),
            title: 'Medieval origins',
            tag: 'Development, Design',
            video: true,
            category: 'branding-3',
          },
          {
            id: 19,
            img: require(`@/assets/images/gallery/img_19.jpg`),
            title: 'Designer’s Checklist',
            tag: 'Development, Plugin',
            category: 'dev-3',
          },
          {
            id: 20,
            img: require(`@/assets/images/gallery/img_20.jpg`),
            title: 'Motivation defining Moment',
            tag: 'Development, Design',
            category: 'plugin-3',
          },
          {
            id: 21,
            img: require(`@/assets/images/gallery/img_21.jpg`),
            title: 'Our experience WordCamp',
            tag: 'Development, Branding',
            video: true,
            category: 'design-3',
          },
          {
            id: 22,
            img: require(`@/assets/images/gallery/img_22.jpg`),
            title: 'Lets catch up amd',
            tag: 'Development, Design',
            category: 'plugin-3',
          },
          {
            id: 23,
            img: require(`@/assets/images/gallery/img_23.jpg`),
            title: 'Experience WordCamp',
            tag: 'Development, Branding',
            category: 'dev-3',
          },

          // portfolio slider
          {
            id: 24,
            img: 'https://uploads-ssl.webflow.com/63994dae1033718bee6949ce/63fd39cd72a20d1ba04c4e4f_meta-img.jpeg',
            subtitle: 'A platform for web3 analytics.',
            title: 'ChatGPT',
          },
          {
            id: 25,
            img: 'https://uploads-ssl.webflow.com/63994dae1033718bee6949ce/63d960ab2f85c36cbea9e835_Astria.jpg',
            subtitle: 'Trains AI Image models for you on their servers',
            title: 'Bard',
          },
          {
            id: 26,
            img: 'https://uploads-ssl.webflow.com/63994dae1033718bee6949ce/6419346909c3f66047021d9c_Timworks%2BSocial%2BShare.png',
            subtitle:
              'AI-powered calendar assistant that helps users streamline their schedules',
            title: 'AssemblyAi',
          },
          {
            id: 27,
            img: 'https://uploads-ssl.webflow.com/63994dae1033718bee6949ce/63eec9905cd8774acba21c1a_sms-ad.png',
            subtitle: 'Content generator and CRM',
            title: 'DeskiAi job board',
          },

          // portfolio v5
          {
            id: 28,
            img: require(`@/assets/images/gallery/img_28.jpg`),
            title: 'The Gang Blue',
            tag: 'Development, Design',
            category: 'creative-4',
          },
          {
            id: 29,
            img: require(`@/assets/images/gallery/img_29.jpg`),
            title: 'Core WordPress',
            tag: 'Development, Plugin',
            video: true,
            category: 'plugin-4',
          },
          {
            id: 30,
            img: require(`@/assets/images/gallery/img_30.jpg`),
            title: 'Platform Audit',
            tag: 'Development, Branding',
            category: 'design-4',
          },
          {
            id: 31,
            img: require(`@/assets/images/gallery/img_31.jpg`),
            title: 'Medieval origins',
            tag: 'Development, Design',
            video: true,
            category: 'branding-4',
          },
          {
            id: 32,
            img: require(`@/assets/images/gallery/img_32.jpg`),
            title: 'Designer’s Checklist',
            tag: 'Development, Plugin',
            large_size: true,
            category: 'dev-4',
          },
        ],
      }
  }
}