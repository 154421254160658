<template>
  <div >
    <Header11/>
    <ComingSoonVue/>
    <ContactStyle/>
    <TestArea/>
  </div>
</template>

<script>
import Header11 from './Header-11.vue';
//import ContactStyle from "./SubmitForm.vue";
import ComingSoonVue from './ComingSoon.vue';
//import TestArea from "./TestArea.vue";

export default {
    name: "ContactUsCsMain",
    components: { 
    Header11,
    //ContactStyle,
    ComingSoonVue,
    //TestArea
    }
}
</script>