import { createApp } from 'vue'
import VueEasyLightbox from 'vue-easy-lightbox'
import router from './routes'
import 'bootstrap'
import './index.css'
import { IonicVue } from '@ionic/vue'
import VueGtag from 'vue-gtag'
import App from './App.vue'

let app = createApp(App)
app.use(router)
app.use(
  VueGtag,
  {
    config: { id: 'G-8B4YVSBY3L' },
  },
  router,
)
app.use(VueEasyLightbox)
app.use(IonicVue)
app.mount('#app')
