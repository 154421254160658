<template>
	<div 
	class="main-page-wrapper overflow-hidden">
		<Header />
		<!-- portfolio details start -->
		<div 
		data-aos="fade-down"
        data-aos-duration="1500" 
		class="portfolio-details-one mb-150 md-mb-100">
			<div class="container">
				<div class="row">
					<div class="col-xl-11 m-auto">
						<div class="header text-center">
							<div class="tag font-gordita">{{ portfolio.category_1}} {{ portfolio.category_2}}</div>
							<div class="title-style-ten">
								<h2>{{ portfolio.title }}</h2>
							</div>
						</div>
					</div>
				</div>

				<div class="main-content mt-75">
					<img :src="portfolio.image" alt="" class="mb-90 md-mb-50">
					<div class="row">
						<div class="col-xl-11 m-auto">
							<div class="row">
								<div class="col-md-8 order-md-first detail-body">
									<h4>Overview</h4>
									<p>{{ portfolio.description }}</p>
									<h4>Pricing Model</h4>
									<p>{{ portfolio.price_detail }}</p>
									<a :href="portfolio.external_link" class="theme-btn-nine" target="_blank">Visit {{ portfolio.title }}</a>
								</div>
							</div>
							<br><br>
							<SimilarTools />
<!-- 							<div class="top-border mt-70 pt-50 md-mt-40">
								<ul class="portfolio-pagination d-flex justify-content-between">
									<li>
										<a href="#" class="d-flex align-items-center">
											<img src="@/assets/images/gallery/img_34.jpg" alt="" class="d-none d-lg-block">
											<span class="d-inline-block ps-lg-4">
												<span class="tp1 d-block">Previous</span>
												<span class="tp2 d-block">Product Branding</span>
											</span>
										</a>
									</li>
									<li>
										<a href="#" class="d-flex flex-row-reverse align-items-center text-end">
											<img src="@/assets/images/gallery/img_35.jpg" alt="" class="d-none d-lg-block">
											<span class="d-inline-block pe-lg-4">
												<span class="tp1 d-block">Next</span>
												<span class="tp2 d-block">Uber App Design</span>
											</span>
										</a>
									</li>
								</ul>
							</div> -->
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<!-- portfolio details end -->
		<Footer/>
	</div>
</template>

<script>
import Header from './Header.vue';
import portfolioMixin from '@/mixin/aitools-mixin-new';
import Footer from './Footer.vue';
//import SimilarTools from './SimilarTools.vue';

export default {
	name: 'DynamicPortfolioDetails',
	mixins: [portfolioMixin],
	components: {
		Header,
		Footer,
		//SimilarTools,
	},
	data() {
		return {
			id: this.$route.params.id,
			portfolio: {}
		}
	},
	methods: {
		getPortfolio(portfolioId) {
			this.portfolio = this.docs.find(item => item.id == portfolioId);
		}
	},
	created() {
		this.getPortfolio(this.id);
	}
}
</script>
<style scoped>
.detail-body{
	font-family: "gordita-regular" !important;
	}
</style>