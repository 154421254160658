<template>
  <div class="mmain-page-wrapper font-gordita overflow-hidden">
    <Header/>
    <HeroBanner/>
    <Trending/>
    <New/>
    <EditorPicks/>
    <Featured/>
    <Footer/>
  </div>
</template>

<script>
import Header from './Header.vue';
//import HeroBanner from './HeroBanner.vue';
import Trending from './trending.vue';
import New from './newest.vue';
import EditorPicks from './EditorPicks.vue';
//import Featured from './Featured.vue';
import Footer from './Footer.vue';

export default {
    name: "WebsiteBuilderMain",
    components: { 
    Header,
    //HeroBanner, 
   // Featured,
    EditorPicks,
    Trending, 
    New,
    Footer }
}
</script>