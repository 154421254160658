<template>
  <div class="search-block"></div>
  <div class="search-options-banner">
    <ion-grid>
      <ion-row>
        <ion-col size=".5"> </ion-col>
        <ion-col size="4">
          <!-- <ion-searchbar class="custom" mode="ios"></ion-searchbar> -->
          <input type="text" v-model="search" placeholder="Search tools..." />
        </ion-col>
        <ion-col size=".5"> </ion-col>
        <ion-col size="4">
          <Multiselect
            v-model="selectedCategories"
            :options="categories"
            :multiple="true"
            :close-on-select="false"
            :limit="2"
            placeholder="Category"
          >
          </Multiselect>
        </ion-col>
        <ion-col size=".2"> </ion-col>
        <ion-col size="1.5">
          <Multiselect
            v-model="selectedPriceModel"
            :options="price_models"
            :multiple="true"
            :close-on-select="false"
            :limit="1"
            placeholder="Price"
          >
          </Multiselect>
        </ion-col>
        <ion-col size=".5"></ion-col>
      </ion-row>
    </ion-grid>
  </div>

  <div class="search-results">
    <div class="fancy-portfolio-two lg-container search-result">
      <div class="container">
        <div class="filter-bar">
          <!--           <input type="text" v-model="search" placeholder="Search tools..." />
          <select v-model="selectedCategories" multiple class="my-select">
            <option value="">All Categories</option>
            <option
              v-for="(category, index) in categories"
              :key="index"
              :value="category"
            >
              {{ category }}
            </option>
          </select>

          <select v-model="selectedPriceModel" multiple class="my-select">
            <option value="">All Prices</option>
            <option
              v-for="(price_model, index) in price_models"
              :key="index"
              :value="price_model"
            >
              {{ price_model }}
            </option>
          </select> -->
        </div>
        <div class="bottom-border pb-130">
          <div class="mixitUp-container gutter-space-one d-flex flex-wrap">
            <div
              v-for="item in filteredTools"
              :key="item.id"
              class="mix"
              data-aos="fade-right"
            >
              <ion-card mode="ios">
                <img
                  :alt="item.title"
                  :src="item.image"
                  width="640"
                  height="250"
                />
                <!-- previous 640 by 360 -->
                <ion-card-header>
                  <ion-card-subtitle class="px-3 pt-2"
                    ><span :style="getPriceColor(item.price)"
                      >{{ item.price }}
                    </span></ion-card-subtitle
                  >
                  <ion-card-title class="px-3"
                    ><router-link :to="`/tool/${item.id}`" target="_blank">{{
                      item.title
                    }}</router-link></ion-card-title
                  >

                  <ion-card-subtitle class="px-3 pt-2"
                    >{{ item.category_1 }} {{ " " + item.category_2 }}
                  </ion-card-subtitle>
                </ion-card-header>
                <ion-card-content
                  >{{ item.short_description }}
                </ion-card-content>
              </ion-card>
              <!-- /.portfolio-block-two -->
            </div>
          </div>
          <!-- /.mixitUp-container -->
          <div class="centeredButton">
            <button
              v-if="showLoadMoreButton"
              @click="loadMore"
              class="theme-btn-nine text-center"
            >
              Load More
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import portfolioMixin from "@/mixin/aitools-mixin";
import Multiselect from "vue-multiselect";
import {
  //IonSearchbar,
  IonCol,
  IonGrid,
  IonRow,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
} from "@ionic/vue";

export default {
  name: "FancyPortfolio",
  mixins: [portfolioMixin],
  components: {
    Multiselect,
    //IonSearchbar,
    IonCol,
    IonGrid,
    IonRow,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
  },
  data() {
    return {
      value: [],
      filtered_p_items: [],
      p_items: [],
      isActive: "all",
      visible: false,
      index: 0,
      categories: [
        "AI Detection",
        "Aggregators",
        "Avatar",
        "Chat",
        "Copywriting",
        "Finance",
        "For Fun",
        "Gaming",
        "Generative Art",
        "Generative Code",
        "Generative Video",
        "Image Improvement",
        "Image Scanning",
        "Inspiration",
        "Marketing",
        "Motion Capture",
        "Music",
        "Podcasting",
        "Productivity",
        "Prompt Guides",
        "Research",
        "Self-Improvement",
        "Social Media",
        "Speech-To-Text",
        "Text-To-Speech",
        "Text-To-Video",
        "Translation",
        "Video Editing",
        "Voice Modulation",
      ], // list of categories
      price_models: [
        "Free",
        "Freemium",
        "GitHub",
        "Google Colab",
        "Open Source",
        "Paid",
      ],
      search: "", // search term
      selectedCategories: [], // selected category filter
      selectedPriceModel: [], // selected price filter
      visibleCount: 20, // number of products to show initially
    };
  },
  computed: {
    filteredTools() {
      // apply search and category filters
      return this.p_items
        .filter(
          (tool) =>
            tool.title.toLowerCase().includes(this.search.toLowerCase()) &&
            (this.selectedCategories.length === 0 ||
              this.selectedCategories.includes(tool.category_1) ||
              this.selectedCategories.includes(tool.category_2)) &&
            (this.selectedPriceModel.length === 0 ||
              this.selectedPriceModel.includes(tool.price))
        )
        .slice(0, this.visibleCount);
    },
    showLoadMoreButton() {
      console.log(this.search);
      // check if there are more products to show
      return this.visibleCount < this.p_items.length;
    },
  },
  methods: {
    getPriceColor(price) {
      if (price == "Paid") {
        return { color: "red" };
      } else if (
        price == "Freemium" ||
        price == "GitHub" ||
        price == "Google Colab" ||
        price == "Open Source"
      ) {
        return { color: "orange" };
      } else {
        return { color: "green" };
      }
    },
    loadMore() {
      // increase the number of visible products
      this.visibleCount += 20;
    },
  },
  mounted() {
    this.p_items = this.aiItems.slice(0, 2000);
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>
.search-block {
  background: #ffffff;
  height: 68px;
  position: relative;
}
.search-options-banner {
  background: #ffffff;
  height: 90px;
  position: relative;
  padding-top: 30px;
  border-bottom: 1px solid #efefef;
}
input {
    border-radius: 52px;
    width: 650px;
    height: 51px;
    border: 1px solid #a0a0a0;
    background: #fafafa;
    padding-left: 20px;
    font-size: 14px;
}
/* .search-results {
  max-height: 90vh;
  overflow-y: auto;
} */

.mixitUp-container.gutter-space-one .mix {
    width: 450px !important;
    height: 500px !important ;
}
.mixitUp-container.gutter-space-one {
    margin: 0 14px;
}
.centeredButton {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}
.lg-container .container {
  max-width: 2384px;
}
.container {
  padding-right: 50px;
  padding-left: 50px;
  padding-top: 20px;
}
</style>
<style>

.multiselect {
    box-sizing: content-box;
    display: block;
    position: relative;
    width: 100%;
    min-height: 40px;
    text-align: left;
    color: #000000;
  } 
  .multiselect__tags {
    min-height: 0;
    display: block;
    border-radius: 31px;
    border: 1px solid #a0a0a0;
    background: #fafafa;
    font-size: 14px;
  }
  .multiselect__tag {
    position: relative;
    display: inline-block;
    padding: 4px 26px 4px 10px;
    border-radius: 40px;
    margin-right: 10px;
    color: #fff;
    line-height: 2;
    background: #242424;
    margin-bottom: -6px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
  }
  .multiselect__tag-icon {
    cursor: pointer;
    margin-left: 7px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    font-weight: 700;
    font-style: initial;
    width: 22px;
    text-align: center;
    line-height: 35px;
    transition: all 0.2s ease;
    border-radius: 5px;
  }
  .multiselect__tag-icon::after {
    content: "×";
    color: #ffffff;
    font-size: 14px;
  }
    .multiselect__select {
    line-height: 16px;
    display: block;
    position: absolute;
    box-sizing: border-box;
    width: 40px;
    height: 44px;
    right: 1px;
    top: 1px;
    padding: 4px 8px;
    margin: 0;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s ease;
  }
    .multiselect__strong {
    margin-bottom: 8px;
    line-height: 35px;
    display: inline-block;
    vertical-align: top;
  }

  .multiselect__option--highlight {
    background: #fffd01;
    outline: none;
    color: rgb(0, 0, 0);
  }

  .multiselect__option--highlight::after {
    content: attr(data-select);
    background: #fffd01;
    color: #fffd01
  }
    .multiselect__option--selected {
    background: #f3f3f3;
    color: #35495e;
    font-weight: bold;
  }

  .multiselect__option--selected::after {
    content: attr(data-selected);
    color: #f3f3f3;
  background: inherit;
  }

  .multiselect__option--selected.multiselect__option--highlight {
    background: #ff6a6a;
    color: #fff;
  }

  .multiselect__option--selected.multiselect__option--highlight::after {
    background: #ff6a6a;
    content: attr(data-deselect);
    color: #ff6a6a;
  }
</style>
