export default {
  data() {
    return {
      Items: [
        {
          id: 224,
          title: 'D-ID',
          short_description:
            'Video creation from plain text in multiple languages.',
          description:
            "D-ID is an AI-generated video creation platform that makes creating high-quality, cost-effective, and engaging videos from plain text easy and fast. Its Creative Reality™ Studio is powered by Stable Diffusion and GPT-3 and can output videos in over 100 languages without any technical knowledge.  D-ID's Live Portrait feature creates videos from a single photo and Speaking Portrait provides a voice to text or audio. Its API is trained on tens of thousands of videos to produce photorealistic results.  D-ID has been used by leading marketing agencies, production companies, and social media platforms to create stunning videos at a fraction of the cost of traditional methods and has won awards from Digiday, SXSW, and TechCrunch.",
          internal_link: 'https://findmyai.co/tool/d-id/',
          category_1: 'Image to video',
          merged_category: 'Image to video image video generator      ',
          date_added: '06 Mar 2021',
          image: 'https://media.theresanaiforthat.com/d-id.png',
          img: 'https://findmyai.co/media/d-id.png',
          price: 'Paid',
          price_detail: 'From $49/mo',
          external_link: 'https://www.d-id.com/?ref=findmyai',
          upvote: null,
          editors_pick: null,
        },
        {
          id: 2672,
          title: 'DailyBot',
          short_description: 'AI-focused news and resource hub',
          description:
            'DailyBot is a virtual assistant designed to make teams more agile and efficient. It offers a range of features including check-ins, kudos, mood tracking, dailybot+ AI, forms, fun add-ons, and automation workflows. It integrates with Slack, Microsoft Teams, Google Chat, and Discord. It helps teams with standups, retros, peer recognition and rewards, team motivation, surveys and data collection, watercoolers, and custom commands. DailyBot also provides AI capabilities to chat and workflows, allowing for more informed decision making and streamlined processes.',
          price: 'Paid',
          internal_link: 'dailybot',
          external_link: 'https://futuretools.link/dailybot',
          image:
            'https://uploads-ssl.webflow.com/63994dae1033718bee6949ce/6407dbcaf7210866cb8c986d_6405319bf813ce660b042f4c_dailybotai-seo.png',
          upvote: 4,
          category_1: 'Chat',
          category_2: '',
          editors_pick: false,
        },
        {
          id: 500,
          title: 'Bardeen',
          short_description:
            'Automation app automates workflows and web app control.',
          description:
            "Bardeen is a powerful automation app designed to help people reduce the time and effort required to complete repetitive tasks. It offers a single-click shortcut to automate workflows and control web apps from anywhere.  Bardeen provides hundreds of pre-built playbooks to help users stay in the flow, as well as an intuitive Builder feature to create custom workflows with building blocks.  Additionally, Bardeen's AI-driven automations feature allows users to save time by automating manual processes. Bardeen supports integration with popular web apps like Airtable, Asana, Clearbit, ClickUp, Coda, Dropbox, Github, Google Calendar, Google Docs, Google Drive, Google Mail, Google Meet, Google Sheets, Jira, Notion, OpenAI, Pipedrive, Slack, and Telegram, allowing users to access and control these applications from anywhere.  Furthermore, Bardeen is free to use, making it a cost-effective solution for automating tasks.",
          internal_link: 'https://findmyai.co/tool/bardeen/',
          category_1: 'Task automation',
          merged_category:
            'Task automation productivity business analysis data task  ',
          date_added: '08 Mar 2022',
          image: 'https://media.theresanaiforthat.com/bardeen.png',
          img: 'https://findmyai.co/media/bardeen.png',
          price: null,
          price_detail: null,
          external_link: 'https://www.bardeen.ai/?ref=findmyai',
          upvote: null,
          editors_pick: null,
        },
        {
          id: 1735,
          title: 'MapsGPT',
          short_description:
            'Helps users quickly find and explore interesting places near them',
          description:
            "Helps users quickly find and explore interesting places near them using AI. Type in what you're looking for, where you're looking for it, and your email. The app will send you a custom map with what you're looking for.",
          price: 'Free',
          internal_link: 'mapsgpt',
          external_link: 'https://futuretools.link/mapsgpt',
          image:
            'https://uploads-ssl.webflow.com/63994dae1033718bee6949ce/63dc0dd91f9aeb49caa808aa_63d17336ea89130b5e48ca99_MapsGPT%2520SEO%2520Image%2520(2).png',
          upvote: 33,
          category_1: 'For Fun',
          category_2: '',
          editors_pick: false,
        },
      ],
    }
  },
}
