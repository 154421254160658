<template>
  <div class="main-page-wrapper p0 overflow-hidden">
    <div class="error-page d-lg-flex align-items-center">
      <div class="img-holder order-lg-last">
        <img src="../assets/images/media/404.svg" alt="" class="w-100 illustration">
        <img src="../assets/images/media/404-q.svg" alt="" class="shapes qus">
      </div>
      <div class="text-wrapper order-lg-first">
        <div class="logo"><router-link to="/"><img src="@/assets/images/logo/logoV8.svg" alt=""></router-link></div>
        <h1 class="font-slab">Sorry, <br>The Page Can’t <br>be Found.</h1>
        <p class="font-rubik">"That page seems to have taken a long walk off a short server. We'll try to track it down, but in the meantime, can we interest you in a virtual hug? 🤗"</p>
        <router-link to="/" class="theme-btn-nine ">
          <span>Back to Home</span>
        </router-link>
      </div> <!-- /.text-wrapper -->
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound"
}
</script>