<template>
  <div
    :class="`theme-main-menu sticky-menu theme-menu-five ${
      isSticky ? 'fixed' : ''
    }`"
  >
    <div class="d-flex align-items-center justify-content-center">
      <div class="logo">
        <router-link to="/">
          <img src="@/assets/images/logo/logoV8.svg" alt="" />
        </router-link>
      </div>

      <nav id="mega-menu-holder" class="navbar navbar-expand-lg">
        <div class="nav-container">
          <button
            class="navbar-toggler"
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavbar"
            :class="menuActive ? 'navbar-toggler open' : 'navbar-toggler'"
            @click="menuActive = !menuActive"
          >
            <span></span>
          </button>
          <div
            :class="
              menuActive
                ? 'navbar-collapse collapse ss'
                : 'navbar-collapse collapse'
            "
            id="collapsibleNavbar"
          >
            <div class="d-lg-flex justify-content-between align-items-center">
              <ul
                class="navbar-nav main-side-nav font-gordita"
                id="one-page-nav"
              >
                <li class="nav-item dropdown position-static">
                  <a
                    class="nav-link"
                    href="/"
                    >Home</a
                  >
                </li>
                <li class="nav-item">
                  <a href="/discover" class="nav-link"
                    ><span @click.self="menuActive = !menuActive"
                      >Discover</span
                    ></a
                  >
                </li>
                <!-- <li class="nav-item">
                  <a href="/search" class="nav-link"
                    ><span @click.self="menuActive = !menuActive"
                      >Search</span
                    ></a
                  >
                </li> -->
                <li class="nav-item">
                  <a href="/contact" class="nav-link"
                    ><span @click.self="menuActive = !menuActive"
                      >Contact</span
                    ></a
                  >
                </li>
                <!-- <li class="nav-item">
                  <a href="/feedback" class="nav-link"
                    ><span @click.self="menuActive = !menuActive"
                      >Feedback</span
                    ></a
                  >
                </li> -->
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <div class="right-widget">
        <ul class="d-flex align-items-center">
          <li>
      <div><a href="/#problem" class="get-start-btn"> Find My AI <i class="fa  ms-1"
              aria-hidden="true"></i></a></div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "HeaderTen",
  components: {
  },
  data() {
    return {
      isSticky: false,
      menuActive: false,
    };
  },
  methods: {
    handleSticky() {
      if (window.scrollY > 80) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleSticky);
  },
};
</script>
<style scoped>
.theme-main-menu {
    background: #f9f8f4;
    padding: 10px 70px;
    
}
.theme-main-menu.sticky-menu.fixed {
  background: #f9f8f4;
  box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.1);
}
.navbar-nav .nav-item .nav-link {
    padding: 30px 0;
    margin: 0 20px;
    display: block;
    color: #0a2a30;
    font-size: 18px;
    text-transform: capitalize;
    position: relative;
}

.navbar-nav .nav-item .nav-link:hover {
    color: #B9A889;
}
.get-start-btn {
  line-height: 40px;
  text-align: center;
  margin-right: 50px;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  border: 2px solid #0a2a30;
  background-color: #0a2a30;
  border-radius: 60px;
  padding: 0 20px;
  transition: all 0.3s ease-in-out;
}

.get-start-btn:hover {
  background: #f9f8f4;
  border-color: #0a2a30;
  color: #0a2a30;
}

</style>
