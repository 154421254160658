<template>
  <footer class="theme-footer-seven mt-120 md-mt-100">
    <div class="lg-container">

				<div class="container">
					<div class="bottom-footer mt-50 md-mt-30">
						<div class="row">
							<div class="col-lg-6 order-lg-last mb-20">
								<ul class="d-flex justify-content-center justify-content-lg-end social-icon">
									<li><a href="https://www.facebook.com/findmyai"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
									<li><a href="https://www.twitter.com/findmyai"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
									<li><a href="https://www.linkedin.com/company/findmyai"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
								</ul>
							</div>
							<div class="col-lg-6 order-lg-first mb-20">
								<p class="copyright text-center text-lg-start">Copyright @2023 findmyai.</p>
							</div>
						</div>
					</div>
				</div>
    </div> <!-- /.lg-container -->

  </footer>
</template>

<script>
export default {
  name: 'FooterEight',
  methods: {
    onSubmit() {
      console.log('Submitted');
    },
  },
}
</script>