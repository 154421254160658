export default {
  data() {
    return {
      Items: [
        {
          id: 1773,
          title: 'Booth.AI',
          short_description: 'AI trivia game generator',
          description:
            'Booth AI is a generative AI platform that enables users to quickly and easily generate high-quality product photography with AI. The platform allows users to create unique, engaging product images in just three simple steps: uploading reference photos of a product, submitting a text prompt describing the desired vision, and receiving images in seconds. Booth AI also includes editing tools and provides high-resolution (4k) images that are ready for use on websites or in marketing materials.',
          price: 'Paid',
          internal_link: 'booth-ai',
          external_link: 'https://futuretools.link/booth-ai',
          image:
            'https://uploads-ssl.webflow.com/63994dae1033718bee6949ce/63c2edc521eff5f049658fb8_63bcd720be351f03c95bf25f_open-graph.png',
          upvote: 25,
          category_1: 'Marketing',
          category_2: 'Generative Art',
          editors_pick: false,
        },
        {
          id: 2681,
          title: 'Ariana AI',
          short_description:
            'Uses AI to quickly create resumes and cover letters from a LinkedIn profile',
          description:
            'Ariana AI is a tool designed to help users with their day-to-day tasks. It is available as a WhatsApp chatbot, where users can ask Ariana questions or to come up with ideas and get an immediate answer. Ariana also offers support for students, parents, work, freelancers, and creatives. The service is offered with a monthly or yearly subscription, with a free trial available and no credit card required. Users can also contact Timworks for further help.',
          price: 'Freemium',
          internal_link: 'ariana',
          external_link: 'https://futuretools.link/ariana',
          image:
            'https://uploads-ssl.webflow.com/63994dae1033718bee6949ce/6419346909c3f66047021d9c_Timworks%2BSocial%2BShare.png',
          upvote: 26,
          category_1: 'Chat',
          category_2: '',
          editors_pick: false,
        },
        {
          id: 2993,
          title: 'Adobe Firefly',
          short_description: 'Generated creative content creation.',
          description:
            'The Adobe Firefly AI Art Generator is a tool that allows users to create images, vectors, videos, and 3D designs from text. It is designed to assist creators in expanding their natural creativity by using generative AI tools, specifically made for creative needs, use cases, and workflows.  Beta testers can use everyday language to generate unprecedented content quickly. Firefly allows users to experiment and make an infinite range of creations, including custom vectors, brushes, and textures from just a few words, sketches, or even mood boards.  Creators can simplify video editing and change the mood, atmosphere, or weather instantly. Firefly also offers users distinct content creation options for marketing and social media, such as creating unique posters, banners, and social media posts from a single line of text.  The tool offers future-forward 3D editing, including turning simple compositions into photorealistic images and quickly creating new styles and variations of 3D objects.  Adobe is committed to developing creative generative AI responsibly, with creators at the center of their mission to give creators every conceivable advantage – not just creatively, but also practically.  As Firefly evolves, Adobe intends to continue working closely with the creative community to build technology that supports and improves the creative process.',
          internal_link: 'https://findmyai.co/tool/adobe-firefly/',
          category_1: 'Image generation',
          merged_category:
            'Image generation image art generator Adobe   Generative Art',
          date_added: '21 Mar 2023',
          image:
            'https://i0.wp.com/artplugged.co.uk/wp-content/uploads/2023/03/Adobe_firefly_art_plugged___0.jpg',
          img: 'https://findmyai.co/media/adobe-firefly.png',
          price: 'Free',
          price_detail: null,
          external_link:
            'https://www.adobe.com/sensei/generative-ai/firefly.html?ref=findmyai',
          upvote: 148,
          editors_pick: 'TRUE',
        },
        {
          id: 312,
          title: 'Altered',
          short_description:
            'A tool to create personalized mobile app templates.',
          description:
            'The Altered Studio Voice Editor is a tool that allows users to create professional voice performances by changing their voice to any of the carefully curated portfolio voices or custom voices. It also allows users to create engaging multi-character performances and clone their voice. The tool also provides users with audio-editing features directly in their browser and provides maximum security.',
          price: 'Paid',
          internal_link: 'altered-ai',
          external_link: 'https://futuretools.link/altered-ai',
          image:
            'https://uploads-ssl.webflow.com/63994dae1033718bee6949ce/63b9e28d2551256faf4d4544_61b9f4a385fa68f24cf0326e_Untitled-1-01.png',
          upvote: 20,
          category_1: 'Voice Modulation',
          category_2: '',
          editors_pick: false,
        },
        {
          id: 968,
          title: 'Astria',
          short_description: 'A platform for web3 analytics.',
          description:
            'Astria AI provides an AI model trained using a set of images provided by the user. For a one-time payment of $5, the service allows you to generate images using textual prompts with a limit of 500 prompts. This model is kept for 1 month, however, the user can delete it at any time. Additionally, the user can extend the model beyond 30 days with a new pricing model. The augment button allows the user to extend the prompt automatically and the model can be trained for more than one person.',
          price: 'Paid',
          internal_link: 'astria-ai',
          external_link: 'https://futuretools.link/astria-ai',
          image:
            'https://uploads-ssl.webflow.com/63994dae1033718bee6949ce/63d960ab2f85c36cbea9e835_Astria.jpg',
          upvote: 26,
          category_1: 'Generative Art',
          category_2: '',
          editors_pick: false,
        },
      ],
    }
  },
}
