<template>
  <div
    :class="`theme-main-menu sticky-menu theme-menu-five ${
      isSticky ? 'fixed' : ''
    }`"
  >
    <div class="d-flex align-items-center justify-content-center">
      <div class="logo">
        <router-link to="/">
          <img src="@/assets/images/logo/logo_4g.svg" alt="" />
        </router-link>
      </div>

      <nav id="mega-menu-holder" class="navbar navbar-expand-lg">
        <div class="nav-container">
          <button
            class="navbar-toggler"
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavbar"
            :class="menuActive ? 'navbar-toggler open' : 'navbar-toggler'"
            @click="menuActive = !menuActive"
          >
            <span></span>
          </button>
          <div
            :class="
              menuActive
                ? 'navbar-collapse collapse ss'
                : 'navbar-collapse collapse'
            "
            id="collapsibleNavbar"
          >
            <div class="d-lg-flex justify-content-between align-items-center">
              <ul
                class="navbar-nav main-side-nav font-gordita"
                id="one-page-nav"
              >
                <li class="nav-item dropdown position-static">
                  <a
                    class="nav-link"
                    href="#"
                    >Home</a
                  >
                </li>
                <li class="nav-item">
                  <a href="#product" class="nav-link"
                    ><span @click.self="menuActive = !menuActive"
                      >Discover</span
                    ></a
                  >
                </li>
                <li class="nav-item">
                  <a href="#feature" class="nav-link"
                    ><span @click.self="menuActive = !menuActive"
                      >Search</span
                    ></a
                  >
                </li>
                <li class="nav-item">
                  <a href="#pricing" class="nav-link"
                    ><span @click.self="menuActive = !menuActive"
                      >Contact</span
                    ></a
                  >
                </li>
                <li class="nav-item">
                  <a href="#feedback" class="nav-link"
                    ><span @click.self="menuActive = !menuActive"
                      >Feedback</span
                    ></a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <div class="right-widget">
        <ul class="d-flex align-items-center">
          <li>
   <div><a href="#" class="get-start-btn"> Feedback <i class="fa  ms-1"
              aria-hidden="true"></i></a></div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "HeaderTen",
  components: {
  },
  data() {
    return {
      isSticky: false,
      menuActive: false,
    };
  },
  methods: {
    handleSticky() {
      if (window.scrollY > 80) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleSticky);
  },
};
</script>
<style scoped>
.theme-main-menu {
    background: #f9f8f4;
}
.theme-main-menu.sticky-menu.fixed {
  background: #f9f8f4;
}
.get-start-btn {
  line-height: 45px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #0a2a30;
  border: 2px solid #0a2a30;
  /* background-color: #0a2a30; */
  border-radius: 50px;
  padding: 0 30px;
  transition: all 0.3s ease-in-out;
}

.get-start-btn:hover {
  background: #0a2a30;
  border-color: #0a2a30;
  color: #ffffff;
}
</style>
