<template>
  <div class="main-page-wrapper p0 font-gordita overflow-hidden">
    <Header11/>
    <SearchArea/>
    <FooterTen :dark_btn="true" />
  </div>
</template>

<script>
import Header11 from './Header.vue';
import SearchArea from './SimpleResults.vue';
import FooterTen from './Footer.vue';

export default {
    name: "WebsiteBuilderMain",
    components: { Header11, 
    SearchArea,
    FooterTen }
}
</script>