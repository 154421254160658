<template>
  <div class="hero-banner-ten">
    <div class="container">
      <div class="row">
        <div class="col-xl-9 col-lg-11 col-md-8 m-auto">
          <h1 class="hero-heading ">Find the <span>right</span> <br><span style="color:#b9a889;"
              >AI tool</span><br> for your needs</h1>
          <p class="hero-sub-heading">Our AI will help you discover the right <br> AI tools for you from over <span style="color:#b9a889; font-weight: 700;" >4000+</span> available in our directory.</p>
        </div>
      </div>
      <div><a href="#" class="get-start-btn">Let's do it! <i class="fa fa-angle-right ms-1"
              aria-hidden="true"></i></a></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeroBanner'
}
</script>
<style scoped>
.hero-banner-ten .hero-heading {
  font-size: 150px;
  font-family: google_sansbold;
  font-weight: 700;
  color: #0a2a30;
  line-height: 130px;
  letter-spacing: -6px;
}
/* Media query for smaller screens */
@media (max-width: 844px) {
  .hero-banner-ten .hero-heading {
    font-size: 170px; /* reduce the font size for smaller screens */
    line-height: 130px;
  }
}

/* Media query for even smaller screens */
@media (max-width: 480px) {
  .hero-banner-ten .hero-heading {
    font-size: 90px; /* reduce the font size even further for smaller screens */
    line-height: 90px;
  }
}
.get-start-btn {
  line-height: 56px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #0a2a30;
  border: 2px solid #0a2a30;
  /* background-color: #0a2a30; */
  border-radius: 50px;
  padding: 0 40px;
  transition: all 0.3s ease-in-out;
}

.get-start-btn:hover {
  background: #0a2a30;
  border-color: #0a2a30;
  color: #ffffff;
}
</style>