<template>
  <div class="main-page-wrapper p0 font-gordita overflow-hidden">
    <HeaderNine/>
    <HeroBanner/>
    <CounterArea/>
    <FancyFeature/>
    <FancyFeature1/>
    <FancyFeature2/>
    <FancyFeature3/>
    <FancyFeature4/>
    <FancyFeature5/>
    <FancyFeature6/>
    <Testimonial/>
    <Partners/>
    <ShortBanner/>
    <FooterNine/>
  </div>
</template>

<script>
import HeaderNine from '../common/Headers/HeaderNine.vue';
import HeroBanner from './HeroBanner.vue';
import CounterArea from './CounterArea.vue';
//import FancyFeature from './Fancy-feature.vue';
import FancyFeature1 from './fancy-feature-1.vue';
//import FancyFeature2 from './Fancy-feature-2.vue';
//import FancyFeature3 from './Fancy-feature-3.vue';
//import FancyFeature4 from './Fancy-feature-4.vue';
//import FancyFeature5 from './FancyFeature-5.vue';
//import FancyFeature6 from './FancyFeature-6.vue';
//import Testimonial from './Testimonial.vue';
//import Partners from './Partners.vue';
import ShortBanner from './ShortBanner.vue';
import FooterNine from '../common/Footers/FooterNine.vue';
export default {
    name: "AppointmentScheduleMain",
    components: { HeaderNine, HeroBanner, 
    CounterArea,
    //FancyFeature, 
    FancyFeature1,
    //FancyFeature2,
    //FancyFeature3, 
    //FancyFeature4,
    //FancyFeature5,
    //FancyFeature6,
    //Testimonial, 
    //Partners, 
    ShortBanner, 
    FooterNine }
}
</script>