<template>
  <div class="main-page-wrapper p0 overflow-hidden">
  <Header11/>
  <ComingSoonNews/>

<!--   
    <div class="fancy-hero-five">
    <img src="../../assets/images/shape/95.svg" alt="" class="shapes shape-one">
    <img src="../../assets/images/shape/96.svg" alt="" class="shapes shape-two">
    <div class="bg-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-lg-7 m-auto text-center">
            <h6 class="page-title">Our News</h6>
            <h1 class="heading">
              <span>Our inside stories<img src="../../assets/images/shape/line-shape-11.svg" alt=""></span>
              lorem que
            </h1>
          </div>
        </div>
      </div>
    </div> 
  </div>
  <div class="blog-page-white-bg blog-v4">
    <div class="container">
      <div class="wrapper">

        <div v-for="(blog, index) in blogs.slice(31, 36)" :key="index"
          :class="`post-meta ${blog.text_post ? 'text-post' : ''}`" data-aos="fade-up" data-aos-duration="1200">
          <img v-if="blog.id !== 33" :src="blog.img" alt="" class="image-meta">
          <div class="post">
            <div class="date">{{ blog.date }}</div>
            <h3>
              <router-link :to="`/blog-details/${blog.id}`" class="title">
                {{ blog.title }}
              </router-link>
            </h3>
            <p>{{ blog.desc }}</p>
            <router-link :to="`/blog-details/${blog.id}`" class="read-more">
              <span>Continue Reading</span>
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </router-link>
          </div>
        </div> 

        <div class="page-pagination-two pt-30">
          <ul class="d-flex align-items-center justify-content-center">
            <li class="arrow"><a href="#"><i class="flaticon-right-arrow"></i></a></li>
            <li class="active"><a href="#">1</a></li>
            <li><a href="#">2</a></li>
            <li><a href="#">3</a></li>
            <li><a href="#">4</a></li>
            <li>...</li>
            <li><a href="#">Last</a></li>
            <li class="arrow"><a href="#"><i class="flaticon-right-arrow"></i></a></li>
          </ul>
        </div> 
      </div> 
    </div>
  </div>
  <FooterTen/>  -->
</div>
</template>

<script>
import blogMixin from '@/mixin/blog-mixin';
import Header11 from './Header-11.vue';
import ComingSoonNews from './ComingSoonNews.vue';


export default {
  name: 'BlogArea',
    components:{
    Header11,ComingSoonNews
    },
  mixins: [blogMixin]
}
</script>


