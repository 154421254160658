<template>
  <!--  WELCOME SECTION -->
  <div v-if="displayWelcomeDiv"
  data-aos="fade-right"
  data-aos-duration="1500"
  class="hero-banner-ten border-bottom">
    <div class="container">
      <div class="row">
        <div class="col-xl-9 col-lg-11 col-md-8 m-auto">
          <h1 class="hero-heading">
            Find the
            <br />right
            <br /><span style="color: #b9a889">AI tool</span>
            <br />
            for your
            <br />
            needs
          </h1>
          <p class="hero-sub-heading">
            Find <span style="color: #0a2a30; font-weight: 700">AI</span> with 
            <span style="color: #0a2a30; font-weight: 700">AI</span>: Discover your ideal AI tool  
            <br> from among <span style="color: #0a2a30; font-weight: 700">{{ counter }}+</span> options!
          </p>
        </div>
      </div>
<!--       <div>
        <a
          href="#start" class="get-start-btn" @click="showStartDiv"
          >Let's do it! <i class="fa fa-angle-right ms-1" aria-hidden="true"></i
        ></a>
      </div> -->
    </div>
  </div>
  <!-- BACK TO TOP -->
        <div>

            <a href="#problem" v-if="showBackToTopButton" class="back-to-top "> <img src="../../assets/images/icon/uparrow.svg" alt=""> </a>
        </div>
        <div class="border-bottom">

<div class="fancy-feature-twentyTwo pt-90 md-pt-120" id="problem">
    <div class="container">
      <div class="row">
        <div
          class="col-xl-7 col-md-8 m-auto"
          data-aos="fade-up"
          data-aos-duration="1200"
        >
          <div class="title-style-eight text-center mb-40 md-mb-10">
            <h2>
              Let's get
              <span
                >started <img src="../../assets/images/shape/191.svg" alt=""
              /></span>
            </h2>
            <p>
            Answer these three quick questions to get your AI tool recommendations.
        </p>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">

      </div>
                        <!--QUESTION 2  -->
      <div class="row justify-content-center">
        <div
          class="col-lg-4 col-md-6"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="100"
        >
          <div class="block-style-twentyTwo">
            <div
              class="icon d-flex align-items-center justify-content-center"
              style="background: #E0F8F8"
            >
              <img src="@/assets/images/icon/68.svg" alt="" />
            </div>
            <h4 style="text-align:center">Problem to Solve</h4>
            <p style="text-align:center"> What do you want AI to help you do?</p>
              <div class="pt-20">
                <textarea
                  placeholder="Example: Plan my meal"
                  rows="2"
                  name="comment[text]"
                  id="comment_text"
                  cols="26"
                  class="ui-autocomplete-input"
                  autocomplete="on"
                  role="textbox"
                  aria-autocomplete="list"
                  aria-haspopup="true"
                  v-model="query"
                ></textarea>
              </div>
          </div>
          <!-- /.block-style-twentyTwo -->
        </div>
        <div
          class="col-lg-4 col-md-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay="100"
        >
          <div class="block-style-twentyTwo">
            <div
              class="icon d-flex align-items-center justify-content-center"
              style="background: #FFEBDB"
            >
              <img src="@/assets/images/icon/67.svg" alt="" />
            </div>
            <h4 style="text-align:center">AI expertise</h4>
            <p style="text-align:center">How would you rate your AI experience? </p>
              <div class="pt-20">
                <div class="expertise-selection ">
                    <input type="radio" id="novice" name="expertise-selection" v-model="selectedExpertise">
                    <label for="novice"> Beginner</label>
                    <br>
                    <input type="radio" id="experienced" name="expertise-selection" v-model="selectedExpertise">
                    <label for="experienced"> Experienced</label>
                    <br>
                    <input type="radio" id="expert" name="expertise-selection" v-model="selectedExpertise">
                    <label for="expert"> Expert</label>
                  </div>
              </div>
          </div>
          <!-- /.block-style-twentyTwo -->
        </div>
                <!-- QUESTION 3 -->
        <div
          class="col-lg-4 col-md-6"
          data-aos="fade-left"
          data-aos-duration="1200"
          data-aos-delay="100"
        >
          <div class="block-style-twentyTwo">
            <div
              class="icon d-flex align-items-center justify-content-center"
              style="background: #F7EDFF"
            >
              <img src="@/assets/images/icon/70.svg" alt="" />
            </div>
            <h4 style="text-align:center">Budget</h4>
            <p style="text-align:center">How much are you willing to Spend?</p>
            <div class="pt-20">
                <div class="price-selection">
                    <input type="checkbox" id="free" name="price-selection" v-model="selectedPrice">
                    <label for="Free"> Free solution</label>
                    <br>
                    <input type="checkbox" id="Cheap" name="price-selection" v-model="selectedPrice">
                    <label for="Cheap"> Not expensive</label>
                    <br>
                    <input type="checkbox" id="Paid" name="price-selection" v-model="selectedPrice">
                    <label for="Paid"> I'm rich</label>
                  </div>
            </div>
          </div>
          <!-- /.block-style-twentyTwo -->
        </div>
      </div>

                      <!-- EXECUTE -->
          <div class="pt-60 pb-60 text-center">
                <a @click="search" href="#result" class="next-step-btn"
                  >Find my AI tool
                  <i class="fa fa-angle-right ms-1" aria-hidden="true"></i
                ></a>
          </div>
    </div>
  </div>


        </div>
  <!-- USER INPUT SECTION -->
  <div 
    class="fancy-feature-twentyFive lg-container"

  >
      <!-- RESULT SECTION -->
    <div id="result"  >
    <div v-if="displayResultDiv"
      class="fancy-feature-eleven pt-100 md-pt-50"
    >
      <div class="inner-container">
        <div
          class="col-xl-9 col-lg-11 m-auto"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          <div class="title-style-eight text-center">
            <h2>Your results are here</h2>
            <p>Yay! We found the perfect tools for you. Explore or try another search.
            </p>
          </div>
        </div>

        <!-- Result 1 -->
        <div
          data-aos="fade-down"
          data-aos-duration="1500"
          class="block-style-sixteen" style="background: #ebf3f1">
          <div class="container">
            <div class="row">
              <div
                class="col-xl-5 col-lg-6"
                data-aos="fade-right"
                data-aos-duration="1500"
              >
                <div
                  class="icon d-flex align-items-center justify-content-center"
                  style="background: #deeae7"
                >
                  <img src="../../assets/images/icon/73.svg" alt="" />
                </div>
                <h3 class="title">{{firstResult.title}}</h3>
                
                <p>{{firstResult.short_description}}</p>
                  <p>
                  <span class="result_detail">Task: </span>{{firstResult.category_1}}
                  </p>
                  <p  class="result_detail" v-if="firstResult.price !== null && firstResult.price !== undefined && firstResult.price !== ''">
                    Pricing Model: <span :class="{ 'green-text': firstResult.price === 'Free', 'red-text': firstResult.price === 'Paid' }">{{firstResult.price_detail}} </span></p><br>
                  
                <a :href="`/tool/${firstResult.id}`" class="next-step-btn"
            target="_blank">More details</a>
              </div>
            </div>
          </div>
          <a :href="firstResult.external_link" target="_blank">
          <img
            :src="firstResult.image"
            alt=""
            class="shapes screen-one result_image"
          />
          </a> 

        </div>


        <!-- Result 2 -->
        <div
        data-aos="fade-down"
          data-aos-duration="1500"
        class="block-style-sixteen" style="background: #edf3f9">
          <div class="container">
            <div class="row">
              <div
                class="col-xl-5 col-lg-6"
                data-aos="fade-right"
                data-aos-duration="1500"
              >
                <div
                  class="icon d-flex align-items-center justify-content-center"
                  style="background: #e1e7ed"
                >
                  <img src="../../assets/images/icon/74.svg" alt="" />
                </div>
                <h3 class="title">{{secondResult.title}}</h3>
                <p>
                  {{secondResult.short_description}}
                  </p>
                  <p>
                  <span class="result_detail">Task: </span>{{secondResult.category_1}}
                  </p>
                  <p class="result_detail" v-if="secondResult.price !== null && secondResult.price !== undefined && secondResult.price !== ''">
                    Pricing Model: <span :class="{ 'green-text': secondResult.price === 'Free', 'red-text': secondResult.price === 'Paid' }">{{secondResult.price_detail}}</span> </p><br>
                  
                <a :href="`/tool/${secondResult.id}`" class="next-step-btn"
            target="_blank">More details</a>
              </div>
            </div>
          </div>
          <a :href="secondResult.external_link" target="_blank">
          <img
            :src="secondResult.image"
            alt=""
            class="shapes screen-one result_image"
          />
          </a>

        </div>


        <!-- Result 3 -->
        <div
        data-aos="fade-down"
          data-aos-duration="1500"
        class="block-style-sixteen" style="background: #f9f1ef">
          <div class="container">
            <div class="row">
              <div
                class="col-xl-5 col-lg-6"
                data-aos="fade-right"
                data-aos-duration="1500"
              >
                <div
                  class="icon d-flex align-items-center justify-content-center"
                  style="background: #ede5e3"
                >
                  <img src="../../assets/images/icon/75.svg" alt="" />
                </div>
                <h3 class="title">{{thirdResult.title}}</h3>
                <p>
                  {{thirdResult.short_description}}
                </p>
                <p>
                  <span class="result_detail">Task: </span>{{thirdResult.category_1}}
                </p>
                  <p class="result_detail" v-if="thirdResult.price !== null && thirdResult.price !== undefined && thirdResult.price !== ''">
                    Pricing Model: <span :class="{ 'green-text': thirdResult.price === 'Free', 'red-text': thirdResult.price === 'Paid' }"> {{thirdResult.price_detail}} </span> </p><br>

                <a :href="`/tool/${thirdResult.id}`" class="next-step-btn"
            target="_blank">More details</a>
              </div>
            </div>
          </div>
          <a :href="thirdResult.external_link" target="_blank">
          <img
            :src="thirdResult.image"
            alt=""
            class="shapes screen-one result_image"
          />
          </a>

        </div>

        <div class="pt-40" style="text-align: center">
          <a href="#problem" class="next-step-btn"
            >Start Over <i class="fa ms-1" aria-hidden="true"></i
          ></a> 
<!--           <a href="#more" class="next-step-btn"
            >Show more <i class="fa ms-1" aria-hidden="true"></i
          ></a> -->
        </div>
        <!-- /.block-style-sixteen -->

      </div>
      <!-- /.inner-container -->
      <img
        src="../../assets/images/shape/139.svg"
        alt=""
        class="shapes shape-one"
      />
      <img
        src="../../assets/images/shape/140.svg"
        alt=""
        class="shapes shape-two"
      />
        <div>
            <!-- Your page content here -->
            <a href="#problem" v-if="showBackToTopButton" class="back-to-top "> <img src="../../assets/images/icon/uparrow.svg" alt=""> </a>
        </div>
      <Footer />
    </div>
    <!-- RESULT SECTION END -->
    </div>
  </div>
</template>

<script>
import Footer from "./Footer.vue";
import aiTools from "@/mixin/aitools-mixin-new";

export default {
  name: "Guest-Journey",
  mixins: [aiTools],
  components: {
    Footer,
  },
  data() {
    return {
      displayWelcomeDiv: true,
      displayStartDiv: true,
      displayProblemDiv: false,
      displayExpertiseDiv: false,
      displayPriceDiv: false,
      displayResultDiv: false,
      displayMoreResultsDiv: false,
      showBackToTopButton: false,
      counter: 0,

      query: '',
      results: [],
      filteredResults: [],
      firstResult: {},
      secondResult: {},
      thirdResult: {},

      isLoading: false,
      error: null,

      selectedPrice:[],
      selectedExpertise:''

    };
  },
  mounted() {
    this.startCounter();
  },
  
  methods: {
    showStartDiv() {
      this.displayStartDiv = true;
      this.displayWelcomeDiv = false;
    },
    showProblemDiv() {
      this.displayProblemDiv = true;
      this.displayStartDiv = false;
      this.displayWelcomeDiv = false;
    },
    showExpertiseDiv() {
      this.displayExpertiseDiv = true;
      this.showBackToTopButton = true;
    },
    showPriceDiv() {
      this.displayPriceDiv = true;
      this.showBackToTopButton = true;
    },
    showResultDiv() {
      this.displayResultDiv = true;
      this.showBackToTopButton = true;
    },
    showMoreResultsDiv() {
      this.displayMoreResultsDiv = true;
      this.showBackToTopButton = true;
    },
    startCounter() {
      this.counter = 3540;
      this.timer = setInterval(() => {
        if (this.counter < 3578) {
          this.counter++;
        } else {
          clearInterval(this.timer);
        }
      }, 100);
    },
    async search() {
      this.displayResultDiv = true
      await this.apiCall();
    },
    async apiCall() {
      this.isLoading = true;
      this.error = null;
      this.results = [];
      try {
          const response = await fetch(`https://findmyaiapi-kv6cvb5ala-uc.a.run.app/predict/${this.query}`)
          if (!response.ok) {
            throw new Error(`HTTP error ${response.status}`);
          }
          const data = await response.json();
          this.results = data
        //FIXME: Add a filter for expertise
        /*   if (this.selectedPrice.length > 0) {
            this.filteredResults = this.priceFilter(this.results.slice(0, 20))
                      this.firstResult = this.filteredResults[0]
                      this.secondResult = this.filteredResults[1]
                      this.thirdResult = this.filteredResults[2]
          }else
          { */
          this.firstResult = this.results[0]
          this.secondResult = this.results[1]
          this.thirdResult = this.results[2]
         /*  } */
      } catch (error) {
          this.error = error.message;
      }

      this.isLoading = false 
      this.displayResultDiv = true
    },
    //TODO: Add a filter for expertise
    priceFilter(results) {
      // Filter the results based on the selected expertise labels
      return results.filter(result => this.selectedPrice.includes(result.price));
    },
  },
};
</script>
<style scoped>
.hero-banner-ten .hero-heading {
  font-size: 150px;
  font-family: google_sansbold;
  font-weight: 700;
  color: #0a2a30;
  line-height: 130px;
  letter-spacing: -6px;
}
.hero-banner-ten .hero-sub-heading {
    font-size: 20px;
    padding: 45px 0 55px;
    color: #0a2a30;
}
.title-style-eight p {
    font-size: 22px;
    color: #0a2a30;
    padding-top: 40px;
}
.title-style-eight h2 {
    font-size: 68px;
    color: #0a2a30;
    font-weight: 700;
    line-height: 1.26em;
}
.block-style-twentyFive p {
    font-size: 24px;
    line-height: 1.91em;
    color: #0a2a30;
}
.block-style-twentyFive .title {
    font-size: 58px;
    line-height: 1.29em;
    font-weight: 500;
    padding-bottom: 38px;
    color: #0a2a30;
}
.get-start-btn {
  line-height: 56px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #0a2a30;
  border: 2px solid #0a2a30;
  /* background-color: #0a2a30; */
  border-radius: 50px;
  padding: 0 40px;
  transition: all 0.3s ease-in-out;
}

.get-start-btn:hover {
  background: #0a2a30;
  border-color: #0a2a30;
  color: #ffffff;
}

.next-step-btn {
  line-height: 56px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  border: 2px solid #0a2a30;
  background-color: #0a2a30;
  border-radius: 50px;
  padding: 0 40px;
  transition: all 0.3s ease-in-out;
}

.next-step-btn:hover {
  background: #f9f8f4;
  border-color: #0a2a30;
  color: #0a2a30;
}

textarea {
  background-color: #fefdfa;
  border: 1px solid #b9a889;
  border-radius: 25px; 
  box-sizing: border-box;
  color: #333;
  font-size: 18px;
  height: 90px;
  padding: 12px;
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0px 0px 10px #f4f3f3;
  
}

textarea:focus {
  border-color: #e6e3dd;
  box-shadow: 0px 2px 5px rgba(0, 4, 0, 0.1);
  outline: none;
}
.block-style-twentyFive p {
  font-size: 20px;
  line-height: 1.91em;
  color: #0a2a30;
}
.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  line-height: 40px;
  text-align: center;
  color: #0a2a30;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #0a2a30;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
}

.back-to-top:hover {
  background: #0a2a30;
  border-color: #0a2a30;
  color: #f9f8f4;
}

.block-style-sixteen {
    margin-top: 88px;
    position: relative;
    border-radius: 105px;
    padding: 68px 44px 43px 86px;
    overflow: hidden;
}

.block-style-sixteen p {
  padding-bottom: 40px;
}

.block-style-twentyTwo {
    text-align: left;
    background: #fff;
    padding: 50px 35px;
    margin-top: 45px;
    border-radius: 63px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.pt-200 {
    padding-top: 200px;
    background: #fff;
    border-radius: 147px;
}

img {
  transition: all 0.3s ease-in;
}

img:hover {
  box-shadow: 0px 30px 80px rgba(246, 247, 247, 0.05);
}

/* hide the default radio button */
input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ccc;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

/* change the radio button color when checked */
input[type="radio"]:checked {
  border-color: #0a2a30;
  background-color: #B9A889;
}

/* add some spacing between the radio button and its label */
input[type="radio"] + label {
  margin-left: 15px;
  color: #0a2a30;
}

/* hide the default checkbox */
input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 2px solid #ccc;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

/* change the checkbox color when checked */
input[type="checkbox"]:checked {
  border-color: #0a2a30;
  background-color: #B9A889;
}

/* add some spacing between the checkbox and its label */
input[type="checkbox"] + label {
  margin-left: 15px;
  color: #0a2a30;
}
.expertise-selection{
  margin-left: 40px;
}
.price-selection{
  margin-left: 40px;
}
.result_image{  
  border-radius: 27px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}
.result_detail{
    font-weight: 900;
    color: #101621;
    font-size: 23px;
}
  .green-text {
    color: rgb(82 189 82);
  }

  .red-text {
    color: rgb(181, 14, 14);
  }

  .border-bottom {
  max-width: 1600px;
  margin: 0 auto;
  border-bottom: 1px solid #e8e8e8 !important;
  padding-bottom: 120px;
}


/* RESPONSIVE STYLES */
 /* Media query for smaller screens */
@media (max-width: 1920px) {
  .hero-banner-ten .hero-heading {
    font-size: 130px; /* reduce the font size for smaller screens */
    line-height: 100px;
  }
}

/* Media query for even smaller screens */
@media only screen and (max-width: 480px) {
  .hero-banner-ten .hero-heading {
    font-size: 90px;
    line-height: 75px;
  }
  .hero-banner-ten .hero-sub-heading {
    font-size: 20px;
}
}


</style>
