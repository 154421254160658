<template>
  <div 
  data-aos="fade-up"
  data-aos-duration="1500" 
  class="fancy-portfolio-five pt-30 lg-container" >
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <div class="col-md-6 col-sm-8">
              <div class="title-style-fourteen">
                <div class="upper-title">DISCOVER</div>
                <h2>Editor picks</h2>
              </div>
        </div>
        <div class="col-lg-5 col-md-6 col-sm-4 d-flex justify-content-center justify-content-sm-end">
          <ul class="slider-arrows d-flex style-none">
            <li class="prev_case3 ripple-btn slick-arrow" style=""><i class="flaticon-right-arrow"></i></li>
            <li class="next_case3 ripple-btn slick-arrow" style=""><i class="flaticon-right-arrow"></i></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="slider-wrapper pt-20 md-pt-50 pb-70">
      <div class="portfolio_slider_one">

        <swiper class="clientSliderOne" ref="mySwiper" :modules="modules" :loop="true" :breakpoints="breakpoints" :navigation="{ nextEl: '.prev_case3', prevEl: '.next_case3' }">
          <swiper-slide v-for="item in Items" :key="item.id">
            <div class="item">
              <div class="portfolio-block-three">
                <router-link :to="`/tool/${item.id}`"  target="_blank" class="tool_showcase">
                      <img :src="item.image" alt="" class="imageSlide"  >
                </router-link>
                  <div class="text">
                    
                    <h4><router-link :to="`/tool/${item.id}`" target="_blank">{{item.title}}</router-link></h4>
                    <p class="category_pill">{{item.category_1}}</p>
                  <div style="font-family: gilroy-regular">{{item.short_description}}</div>
                </div>
              </div> <!-- /.portfolio-block-three -->
            </div>
          </swiper-slide>

        </swiper>

      </div> <!-- /.portfolio_slider_one -->
    </div> <!-- /.slider-wrapper -->
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation } from "swiper";
import editorPicksMixin from "@/mixin/editorPicks-mixin";

export default {
  name: 'PortfolioSlider',
  mixins:[editorPicksMixin],
  components: {
    Swiper,
    SwiperSlide,
  },
  data () {
    return {
        breakpoints: {
        0: { slidesPerView: 1 },
        576: { slidesPerView: 1 },
        768: { slidesPerView: 2 },
        992: { slidesPerView: 3 },
      }
    }
  },
  setup() {
    return {
      modules: [Navigation],
    };
  },
}
</script>
<style scoped>
.imageSlide{
  border: 1px solid rgb(222 220 220); 
  border-radius: 35px;
}
/*   .swiper {
    margin-left: 100px;
    margin-right: 100px;
    position: inherit;
    overflow: inherit;
    list-style: none;
    padding: 0;
    z-index: 1;
}  */

/* Phones */
@media (max-width: 400px){
.fancy-portfolio-five .slider-wrapper {
    margin: 0 2vw;
}}

/* Tablets */
@media (max-width: 850px){
.fancy-portfolio-five .slider-wrapper {
    margin: 0 4vw;
}}

</style>